/* Custom Styles for H2CD Website */

/* Add Noto Sans Display font from Google Fonts */
@import "bootstrap/scss/bootstrap";
@import "variables";
@import "generics";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@400;500;700&family=Cabin:wght@400;500;700&display=swap');


/* Use Noto Sans Display as the default font */
body {
  font-family: 'Noto Sans Display', sans-serif !important;
}

/* import bootstrap */
@import "bootstrap/scss/bootstrap";

#mediaDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 10px;
  max-width:50%;
}

#articleDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.articleContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.titleDateContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.maprow
{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  transition: all 1s ease;
}

.buttongroup {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: flex-end;
}

#hometext {
  text-align: right;
}

#imageStack {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.stacked {
  position: absolute;
  border: 5px solid var(--important-green);
  transition: all 0.5s ease;
}

/* Style the navbar */
.navbar {
  background-color: #FFFFFF;
}

h1, h2, h3,
h4, h5, h6 {
  font-family: "Cabin", Sans-Serif !important;
  font-weight: 500;
}

a, b {
  font-family: "Cabin", Sans-Serif !important;
  font-weight: 500 !important;
  color: var(--important-green) !important;

}

a {
  text-decoration: underline dotted;
}

a:hover {
  text-decoration: underline solid;
}

.navbar-brand {
  font-weight: bold;
  color: #333333;
}

.nav-link {
  color: #333333;
}

/* Style the header carousel */
.carousel-item img {
  height: 500px; /* Adjust the height as needed */
}

.carousel-caption {
  text-align: center;
  color: #ffffff;
}

/* Add spacing and style for sections */
section {
  padding: 50px 0;
}

header {
  background-color: #fff;
}


.navbar-brand,
.navbar-nav .nav-link {
  color: #000;
}

#home {
  background-color: var(--accent-green) !important;
}

.citation-and-badge {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
}

/* Footer Styles */
footer, #products, #partnerships {
  background-color: rgba(204, 237, 237, 0.29);
}

ion-icon {
  color: var(--important-green);
}
.card
{
  height:100%;
}

a > ion-icon:hover {
  color: #000000;
}
.card-img-top
{
  padding: 1vw !important;
}

.fact {
    display: flex;
    align-items: center;
    gap: 15px;

}

.fact > ion-icon {
  font-size: 64px;
}

.d-flex > .fact {
  align-items: center;
  gap: 15px;
  width: 25%;
}

.fact > p > b {
  font-size: 1.2rem;
  color: var(--important-green);
}

.fact p {
  margin-bottom: 0;
}

.social, .left-tray {
  gap: 1vw;
}

footer > .container > .d-flex {
  gap: 10vw;
}

main {
  margin-top: 90px !important;
}


.testimonial-img {
  height: 100px !important;
}

/* Additional custom styles */
/* ... Add your custom styles here */

/* Custom Styles for H2CD Website */

/* ... Existing styles */

/* Style the parallax section */
.parallax-section {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px; /* Adjust the height as needed */
}

.parallax-content {
  color: #ffffff;
  text-align: center;
  padding-top: 200px; /* Adjust the padding as needed */
}

/* Style the background video section */
.bg-video-section {
  position: relative;
  height: 600px; /* Adjust the height as needed */
}

.video-container {
  position: relative;
  height: 100%;
}

.video-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  text-align: center;
  z-index: 1;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Additional custom styles */
/* ... Add your custom styles here */

.logo-marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

img.logo {

}

#map {
  height: 400px;
  width: 100%;
}

.logo {
  display: inline-block;
  height: 30px;
  margin-right: 50px; /* Adjust the space between logos */
  /* Other styles like height, width as needed */
  fill: #0f5132;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.logo-marquee:hover .logo {
  animation: slide 10s linear infinite; /* Adjust timing as needed */
}